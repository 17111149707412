import React, { useState } from "react";
import axios from "axios";

const Contact = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    setSent(true);
    setFirstName("");
    setLastName("");
    setEmail("");

    try {
      const response = await axios.post('https://api.fahimthedream.org/contact', {
        firstName,
        lastName,
        email,
        message
      });

      console.log("Repsonse: ", response.data);
    } catch (error) {
      console.log(JSON.stringify(error));
    };
  }

  return (
    <div>
      <div className="flex flex-col items-center max-w-lg px-4 mx-auto mt-24 mb-12">
        <div className="text-4xl font-bold">Contact Us</div>
        <div className="mt-2 text-xl">We'd love to hear from you. Please note, the foundation does not accept unsolicited requests for funding</div>

        {sent ? (
          <div className="mt-24">
            Thank you for getting in touch!
          </div>
        ) : (
          <form onSubmit={sendEmail} className="w-full p-4 sm:p-0">
            <div className="grid w-full grid-cols-1 mt-24 text-left gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  First name*
                </label>
                <div className="mt-1">
                  <input
                    required
                    type="text"
                    name="first-name"
                    id="first-name"
                    onChange={e => {
                      setFirstName(e.target.value);
                    }}
                    autoComplete="given-name"
                    className="block w-full border-gray-300 rounded-md shadow-sm focus:border-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Last name*
                </label>
                <div className="mt-1">
                  <input
                    required
                    type="text"
                    name="last-name"
                    id="last-name"
                    onChange={e => {
                      setLastName(e.target.value);
                    }}
                    autoComplete="family-name"
                    className="block w-full border-gray-300 rounded-md shadow-sm focus:border-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address*
                </label>
                <div className="mt-1">
                  <input
                    required
                    id="email"
                    name="email"
                    type="email"
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                    autoComplete="email"
                    className="block w-full border-gray-300 rounded-md shadow-sm focus:border-gray-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 ">
                  Comment or message*
                </label>
                <div className="mt-1">
                  <textarea
                    required
                    id="message"
                    name="message"
                    onChange={e => {
                      setMessage(e.target.value);
                    }}
                    rows={3}
                    className="block w-full max-w-lg border border-gray-300 rounded-md shadow-sm focus:border-gray-500 sm:text-sm"
                    defaultValue={''}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="py-4 text-sm font-bold uppercase rounded-md sm:col-span-6 bg-lightgreen"
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
export default Contact;
