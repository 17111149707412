import TheLegacy from "../Assets/img-the-legacy.png";

import art1 from "../Assets/art/01-TimesPicyune.png";
import art2 from "../Assets/art/02-Nickelodeon.jpg";
import art3 from "../Assets/art/03-Robot.png";
import art4 from "../Assets/art/04-Split-Thoughts.jpg";
import art5 from "../Assets/art/05-SelfGreen.jpg";
import art6 from "../Assets/art/06-JohnJayMan.png";
import art7 from "../Assets/art/07-APTests.png";
import art8 from "../Assets/art/08-Jontu.jpg";
import art9 from "../Assets/art/09-Her-min.jpg";
import art10 from "../Assets/art/10-Leaves.jpg";
import art11 from "../Assets/art/11 Reme-min.jpg";
import art12 from "../Assets/art/12 Ideas.jpg";

const Legacy = (props) => {
    return (
        <div>
            <div className="flex flex-col items-center">
                <div className="py-20">
                    <div className="text-xs font-bold uppercase text-lightgreen">His Legacy</div>
                    <div className="text-4xl font-bold">The Legacy of Fahim Saleh</div>
                </div>
                <div>
                    <img src={TheLegacy} />
                </div>
                <div className="py-32">
                    <div className="font-bold text-thegreen text-7xl">creativity</div>
                    <div className="text-lg">/ˌkriːeɪˈtɪvɪti/</div>
                    <div className="text-lg text-gray-400">noun</div>
                    <div className="text-lg">1. The use of imagination or original ideas to create something; inventiveness.</div>
                </div>
                <div className="w-full bg-center bg-no-repeat bg-contain border-t sm:bg-auto py-28 bg-brush">
                    <div className="mt-4 text-4xl italic font-bold">"Creativity is intelligence having fun."</div>
                    <div className="mt-4 text-lg">— Albert Einstein</div>
                </div>
                <div className="flex flex-col pt-32 space-y-6">
                    <div className="p-5 shadow-md border-neutral">
                        <img className="max-w-xs max-h-sm" alt="wiz offer site for teens" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/Wizteen%2C+1996.jpg" />
                        <div className="mt-5 font-bold">Wizteen, 1996</div>
                    </div>
                    <div className="p-5 shadow-md border-neutral">
                        <img className="max-w-xs max-h-sm" alt="tapfury 2011" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/Tapfury%2C+2011.JPG" />
                        <div className="mt-5 font-bold">Tapfury, 2011</div>
                    </div>
                    <div className="p-5 shadow-md border-neutral">
                        <img className="max-w-xs max-h-sm" alt="hackhouse 2015" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/HackHouse%2C+2015.png" />
                        <div className="mt-5 font-bold">HackHouse, 2015</div>
                    </div>
                    <div className="p-5 shadow-md border-neutral">
                        <video className="max-w-xs max-h-sm" alt="Pathao, 2015" controls>
                            <source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/Pathao%2C+2015.mp4" />
                        </video>
                        <div className="mt-5 font-bold">Pathao, 2015</div>
                    </div>
                    <div className="p-5 shadow-md border-neutral">
                        <video className="max-w-xs max-h-sm" alt="Gokada, 2018" controls>
                            <source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/Gokada%2C+2018.mp4" />
                        </video>
                        <div className="mt-5 font-bold">Gokada, 2018</div>
                    </div>
                    <div className="p-5 shadow-md border-neutral">
                        <video className="max-w-xs max-h-sm" alt="Adventure Capital, 2018" controls>
                            <source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/Adventure+Capital%2C+2018.mp4" />
                        </video>
                        <div className="mt-5 font-bold">Adventure Capital, 2018</div>
                    </div>

                </div>

                <div className="w-full max-w-2xl bg-center bg-no-repeat bg-contain sm:bg-auto py-28 bg-brush">
                    <div className="mt-4 text-4xl italic font-bold">"Creativity is inventing, experimenting, growing, taking risks, breaking rules, making mistakes, and having fun."</div>
                    <div className="mt-4 text-lg">— Mary Lou Cook</div>
                </div>

                <div className="flex flex-col items-center justify-center w-full py-20 bg-neutral">
                    <div className="py-4">
                        <div className="text-4xl"><strong>Art</strong> <i>by Fahim</i></div>
                    </div>

                    <div className="flex flex-col justify-center py-2 text-center bg-center bg-no-repeat bg-contain sm:bg-auto bg-brush">
                        <div className="mt-4 text-4xl italic font-bold "><span className="max-w-2xl">“The world is nothing but a canvas to<br /> the imagination.”</span></div>
                        <div className="mt-4 text-lg">— Albert Einstein</div>
                    </div>

                    <div className="flex flex-col flex-wrap max-w-4xl space-y-4 sm:space-x-4 sm:flex-row">
                        <div className="flex">
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="Times Picyune" src={art1} />
                                <div className="mt-5 font-bold">Times Picyune</div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="Nickelodeon" src={art2} />
                                <div className="mt-5 font-bold">Nickelodeon</div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="Robot" src={art3} />
                                <div className="mt-5 font-bold">Robot</div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="Split Thoughts" src={art4} />
                                <div className="mt-5 font-bold">Split Thoughts</div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="Self Green" src={art5} />
                                <div className="mt-5 font-bold">Self Green</div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="John Jay Man" src={art6} />
                                <div className="mt-5 font-bold">John Jay Man</div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="AP Tests" src={art7} />
                                <div className="mt-5 font-bold">AP Tests</div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="Jontu" src={art8} />
                                <div className="mt-5 font-bold">Jontu</div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="Her" src={art9} />
                                <div className="mt-5 font-bold">Her</div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="Leaves" src={art10} />
                                <div className="mt-5 font-bold">Leaves</div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="Reme" src={art11} />
                                <div className="mt-5 font-bold">Reme</div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="Ideas" src={art12} />
                                <div className="mt-5 font-bold">Ideas</div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* <div className="flex flex-col space-x-6 space-y-6 lg:flex-row">
            <div className="p-5 shadow-md border-neutral">
                <video className="max-w-xs max-h-sm" alt="Dronage" controls>
                    <source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Art/Dronage+.mp4" />
                </video>
                <div className="mt-5 font-bold">Dronage</div>
            </div>
            <div className="p-5 shadow-md border-neutral">
                <video className="max-w-xs max-h-sm" alt="G-Balloon in Action" controls>
                    <source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Art/G-Balloon+in+Action.mp4" />
                </video>
                <div className="mt-5 font-bold">G-Balloon in Action</div>
            </div>
            <div className="p-5 shadow-md border-neutral">
                <video className="max-w-xs max-h-sm" alt="Find your monkey" controls>
                    <source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Art/Find+Your+Monkey.mp4" />
                </video>
                <div className="mt-5 font-bold">Find your monkey</div>
            </div>
            {/* <div className="p-5 shadow-md border-neutral">
                <img className="max-w-xs max-h-sm" alt="Reme" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Art/Reme.jpg" />
                <div className="mt-5 font-bold">Reme</div>
            </div> */}

                <div className="flex flex-col justify-center py-16">
                    <div className="text-4xl font-bold">Music</div>

                    <div className="w-full py-10 bg-center bg-no-repeat bg-contain sm:bg-auto bg-brush">
                        <div className="mt-4 text-4xl italic font-bold">"You can't use up creativity. The more you<br /> use the more you have."</div>
                        <div className="mt-4 text-lg">— Maya Angelou</div>
                    </div>

                    <div className="grid grid-cols-1 gap-6 mt-48 lg:grid-cols-3">

                        <div className="p-5 shadow-md border-neutral">
                            <video className="max-w-xs max-h-sm" alt="Going Bad" controls>
                                <source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Music/Going+Bad.mp4" />
                            </video>
                            <div className="mt-5 font-bold">Going Bad</div>
                        </div>
                        <div className="p-5 shadow-md border-neutral">
                            <video className="max-w-xs max-h-xs" alt="John Mayer" controls>
                                <source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Music/John+Mayer+on+Repeat.mp4" />
                            </video>
                            <div className="mt-5 font-bold">John Mayer on Repeat</div>
                        </div>
                        <div className="p-5 shadow-md border-neutral">
                            <video className="max-w-xs max-h-sm" alt="Never Hot" controls>
                                <source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Music/Never+Hot.mp4" />
                            </video>
                            <div className="mt-5 font-bold">Never Hot</div>
                        </div>
                        <div className="p-5 shadow-md border-neutral">
                            <video width="320" className="max-w-xs max-h-sm" alt="Drake" controls>
                                <source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Music/Drake.mov" />
                            </video>
                            <div className="mt-5 font-bold">Drake</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center w-full py-20 bg-neutral">
                    <div className="py-4">
                        <div className="text-4xl">Musings</div>
                    </div>

                    <div className="flex flex-col justify-center py-2 text-center bg-center bg-no-repeat bg-contain sm:bg-auto bg-brush">
                        <div className="mt-4 text-4xl italic font-bold "><span className="max-w-2xl">"Everything you can imagine is real."</span></div>
                        <div className="mt-4 text-lg">— Pablo Picasso</div>
                    </div>

                    <div className="flex flex-col justify-center w-full max-w-3xl mx-auto mt-32">
                        <div className="mt-6 space-y-6">
                            <div className="flex flex-col p-5 bg-white shadow-md border-neutral">
                                <div className="flex flex-col items-center justify-center space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
                                    <img className="w-64 h-96" alt="left" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Musings/Money/Money+Left.jpg" />
                                    <img className="w-64 h-96" alt="right" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Musings/Money/Money+Right.jpg" />
                                </div>
                                <div className="mt-5 font-bold">Money</div>
                            </div>
                            <div className="flex flex-col p-5 bg-white shadow-md border-neutral">
                                <div className="flex flex-col items-center justify-center space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
                                    <img className="w-64 h-96" alt="left" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Musings/On+Investing/On+Investing+Left.jpg" />
                                    <img className="w-64 h-96" alt="right" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Musings/On+Investing/On+Investing+Right.jpg" />
                                </div>
                                <div className="mt-5 font-bold">On Investing</div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 gap-6 mt-6 lg:grid-cols-3">
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="The World is a Game" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Musings/The+World+is+a+Game.jpg" />
                                <div className="mt-5 font-bold">The World is a Game</div>
                            </div>
                            <div className="flex flex-col justify-center p-5 bg-white shadow-md border-neutral">
                                <img className="max-w-xs max-h-sm" alt="Water" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/Musings/Water.jpg" />
                                <div className="mt-5 font-bold">Water</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="text-4xl mt-28">That’s So Fahim</div>

                <div className="w-full py-10 bg-center bg-no-repeat bg-contain sm:bg-auto bg-brush">
                    <div className="mt-4 text-4xl italic font-bold">"Creativity doesn't wait for that perfect<br /> moment. It fashions its own perfect<br /> moments out of ordinary ones."</div>
                    <div className="mt-4 text-lg">— Bruce Garrabrandt</div>
                </div>

                <div className="mb-20 space-y-6">
                    <div className="flex flex-col p-5 shadow-md border-neutral">
                        <div className="flex flex-col items-center justify-center space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
                            <img className="w-64 h-96" alt="left" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/That's+So+Fahim/Jay+Walking+in+Lagos/Left.jpg" />
                            <img className="w-64 h-96" alt="right" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/That's+So+Fahim/Jay+Walking+in+Lagos/Right.jpg" />
                        </div>
                        <div className="mt-5 font-bold">Jay Walking in Lagos</div>
                    </div>
                    <div className="flex flex-col p-5 shadow-md border-neutral">
                        <div className="flex space-x-3">
                            <video className="w-64 h-96" alt="Cruisin'" controls>
                                <source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/legacy/That's+So+Fahim/Cruisin'.mp4" />
                            </video>
                        </div>
                        <div className="mt-5 font-bold">Cruisin'</div>
                    </div>
                </div>
            </div>
        </div >
    );
};
export default Legacy;
