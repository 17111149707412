const Privacy = (props) => {
  return (
    <div>
      <div className="flex flex-col items-center my-24 space-y-4 max-w-5xl mx-auto">
          <div className="font-bold text-4xl">Privacy Policy</div>
          <div>
<div className="text-xl font-bold mt-8 mb-4">Our commitment to privacy</div>
Fahim the Dream Foundation knows that you care how information
about you is used and shared, and we appreciate you trusting that we
will do so carefully and sensibly. This Privacy Policy explains our
practices and the choices you can make about the way yo
ur information
is collected and used by the Foundation. It applies to donor information
that we collect both online and offline.<br/><br/>
We have updated our Privacy Policy to take into account the new
requirements of the General Data Protection Regulation ("GDPR")
which
means if you are a resident of the EU and your donor records reflect that
address, you may be entitled to certain additional protections in
accordance with GDPR.

<div className="text-xl font-bold mt-6 mb-4">What information is collected by Fahim the Dream
Foundation?</div>
We collect personal infor
mation when you voluntarily submit it to us.<br/><br/>
For example, donors may provide us with personal information (such as
name, address, email address, telephone numbers and/or credit/debit
card information). If a donor, or a website visitor, contacts us by email
they may provide us with their email address, and name. Providing data
is required in these contexts in order for us to process your donations,
requests, or respond to inquiries.<br /><br />
In addition to the personal information that you submit to us, we may
collec
t personal information about you from reputable third parties, or
automatically collect information using the following technologies:
Web Server Logs.<br /><br />Our web servers may collect certain information such
as IP address, pages visited, time of visits, and re
ferring website.<br /><br />
Cookies. Cookies are simple text files stored by your web browser that
provide a method of distinguishing among donors and visitors to the
website. Fahim the Dream Foundation uses cookies to identify your
browser as you visit pages on the
Fahim the Dream Foundation website.

<div className="text-xl font-bold mt-6 mb-4">Where do we transfer and store information?</div>
Fahim the Dream Foundation is based in the United States, and any
information transmitted to us may be stored, accessed, or processed in
the United States. Residents of some
other countries, including residents
of the European Union, should be aware that the laws of the United
States do not offer the same protections as the laws of your home
country.

<div className="text-xl font-bold mt-6 mb-4">How do we use information?</div>
We collect information primarily to accept donatio
ns, complete our
charitable projects, and provide you with information concerning our
programs, projects, and activities. We also use information for internal
purposes (to better understand our supporter network) and for analytical
purposes (to respond to
requests for information or merchandise and to
improve our website, donor base, and outreach). We may inform you of
other purposes at the time that we collect some forms of information. For
example, if you apply for a job we may collect information in orde
r to
process your job application.

<div className="text-xl font-bold mt-6 mb-4">With whom do we share information?</div>
We do not trade, share, or sell information that you provide to us with
other organizations who intend to use it only to market their products or
services to you.<br /><br />
There may be instances
in which we share your information with others.
We may share your information, for example, in conjunction with your
sponsorship of a certain project, as required by law, with law
enforcement, to comply with legal process, to protect our legal rights, to
p
rotect the safety or security of a person, or to protect against fraud,
criminal acts, or misconduct. We may also share your information with
companies that provide us with services, or with a successor legal entity.

<div className="text-xl font-bold mt-6 mb-4">Our commitment to data security</div>
To help
prevent unauthorized access, maintain data accuracy, and
ensure the correct use of information, we have put in place appropriate
physical, electronic, and managerial procedures to safeguard and secure
the information we collect. For example, we secure our
machines with
mandated encryption protocols to secure our user/company data. We
also use industry accepted tools and techniques such as SSL and two
-
factor authentication to protect against unauthorized access to our
systems. Additionally, all sensitive in
formation is stored in secure
repositories both in
-
house as well as secure cloud
-
based, AES
encrypted web apps.

<div className="text-xl font-bold mt-6 mb-4">Your access to data, and your choices</div>
If you would like to access the data that we received from you, or make
corrections to it, you may contact
us at the email or mailing addresses
provided at the end of this policy.<br /><br />
If you registered to receive communications from us and later change
your mind, you may ask that your name and contact information be
removed from our distribution list. You can send
such a request to the
email or mailing addresses at the end of this policy.<br /><br />
Some web browsers and devices permit you to broadcast a preference
that you not be "tracked" online. At this time we do not modify your
experience based upon whether such a signal
is broadcast.

<div className="text-xl font-bold mt-6 mb-4">Additional information</div>
Behavioral Advertising. We may participate in behavioral
-
based
advertising. This means that a third party may use technology (e.g., a
cookie) to collect information about your use of our website so that they
can provid
e advertising about products and services tailored to your
interest. That advertising may appear either on our website, or on other
websites.<br /><br />
Links. Our website may contain links to other Web sites. Please note
that when you click on one of these links, yo
u are entering another site
that is governed by that site’s privacy policy.<br /><br />
We implement Google's Invisible reCAPTCHA to protect our site from
spam and abuse. Use of this service is subject to Google's&nbsp;
<a className="underline cursor-pointer" href="https://www.google.com/intl/en/policies/privacy/" target="_blank">privacy policy</a>&nbsp;
and&nbsp;
<a className="underline cursor-pointer" href="https://www.google.com/intl/en/policies/terms/" target="_blank">terms</a>. <br /><br />

Changes to this privacy statement. We may modify this privacy policy
from time to time. Any changes will be reflected on this website. If we
make a material change to this policy, and need to apply it to personal
information that you provided to us before
the change was made, we will
attempt to contact you and obtain your consent to the change.<br /><br />
Communicating with us. If we need, or are required to, contact you about
the privacy or security of your information we may do so by email or
mail. If you have any q
uestions, concerns, requests, or comments about
privacy, you can contact us by email or mail at:
<br /><br />
<a className="underline cursor-pointer" href="/contact">Contact Us by Email</a>
<br /><br />
7 Baird Ct. Syosset, NY 11791
<br /><br />
Effective: December 12, 2021
          </div>
          {/* <div className="max-w-xl">Promoting creativity and entrepreneurship in developing nations through the use of technology and governance, as a means to overcome hardship</div> */}
      </div>
    </div>
  );
};
export default Privacy;
