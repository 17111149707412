import { Link } from "react-router-dom";
import Empower from "../Assets/img-empower.png";
import Skills from "../Assets/img-skills.png";
import Job from "../Assets/img-job.png";
import FahimAndKids from "../Assets/img-fahim-and-kids.jpg";

import Work1 from "../Assets/work-1.jpg";
import Work2 from "../Assets/work-2.jpg";
import Work3 from "../Assets/work-3.jpg";

import VPDA from "../Assets/logo-vpda.png";
import IWS from "../Assets/logo-iws.png";
import NCWD from "../Assets/logo-ncwd.png";
import WIN from "../Assets/logo-win.png";

const Work = (props) => {
  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="flex justify-center w-full max-w-7xl">
          <img src={FahimAndKids} />
        </div>
        <div className="flex justify-end w-full mt-2 mr-4 text-sm max-w-7xl sm:mr-0">
          <div>Gokada Children’s Day, 2018</div>
        </div>
        <div className="pt-12 text-sm font-bold uppercase text-lightgreen">Our Work</div>
        <div className="text-4xl font-bold">
          What We Do
        </div>
        <div className="max-w-3xl px-4 pt-4 text-xl leading-6">
          In line with our mission, the Foundation will equip people with resources in learning and skills acquisition for economic independence and empowerment.</div>
        <div className="flex flex-col py-16 mb-8 space-y-10 sm:flex-row sm:space-x-32 sm:space-y-0">
          <div className="flex flex-col">
            <div className="flex justify-center w-full"><img className="w-24 h-24" src={Empower} /></div>
            <div className="mt-2 text-xl font-bold">Economic<br />Empowerment</div>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-center w-full"><img className="w-24 h-24" src={Skills} /></div>
            <div className="mt-2 text-xl font-bold">Skills Acquisition<br />Training</div>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-center w-full"><img className="w-24 h-24" src={Job} /></div>
            <div className="mt-2 text-xl font-bold">Job Placement and<br />Entrepreneurship</div>
          </div>
        </div>
        <div className="flex flex-col items-center w-full border-t">
          <div className="mt-20 mb-5 text-4xl font-bold">
            Project First Ladies of Lagos
          </div>
          <div className="max-w-3xl px-4 text-xl">Gokada is more than a business, it is a community. Fahim felt a deep responsibility for the livelihood of the Gokada pilots and their families. His vision was not only the advancement of pilots through the creation of business opportunities, but the empowerment of the community beyond the pilots themselves, extending to their wives and children. Fahim understood that in order for the community to thrive, the family must thrive. Family meant the world to Fahim, and he wanted to give as wholeheartedly to the families of his pilots as he did his own family. </div>
          <div className="flex flex-col space-y-10 sm:space-x-10 sm:flex-row sm:mt-20 sm:space-y-0">
            <img src={Work1} className="rounded-md w-72" />
            <img src={Work2} className="rounded-md w-72" />
            <img src={Work3} className="rounded-md w-72" />
          </div>
          <div className="max-w-2xl text-3xl font-bold mt-28">
            The Foundation has partnered with local organizations with shared values to empower The First Ladies of Lagos.
          </div>
          <div className="flex flex-col py-16 mb-8 space-y-10 sm:flex-row sm:space-x-24 sm:space-y-0">
            <Link to={{ pathname: "https://vpdacademy.com" }} target="_blank" className="flex flex-col">
              <div className="flex justify-center w-full"><img className="w-24 h-24" src={VPDA} /></div>
              <div className="mt-2 font-bold">Vocational and<br /> Professional<br /> Development Academy</div>
            </Link>
            <Link to={{ pathname: "https://iwsnigeria.org" }} target="_blank" className="flex flex-col">
              <div className="flex justify-center w-full"><img className="w-24 h-24" src={IWS} /></div>
              <div className="mt-2 font-bold">International Women’s <br /> Society</div>
            </Link>
            <Link to={{ pathname: "https://ncwd.org.ng" }} target="_blank" className="flex flex-col">
              <div className="flex justify-center w-full"><img className="w-24 h-24" src={NCWD} /></div>
              <div className="mt-2 font-bold">National Centre for<br /> Women Development</div>
            </Link>
            <Link to={{ pathname: "https://womenimpactingnigeria.org" }} target="_blank" className="flex flex-col">
              <div className="flex items-center justify-center w-full h-24"><img className="w-48 h-16" src={WIN} /></div>
              <div className="mt-2 font-bold">Women <br />Impacting Nigeria</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Work;
