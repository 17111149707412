import BgDonate from "../Assets/bg-donate.png";
import DonationWidget from "../DonationWidget";

const Donate = (props) => {
  return (
    <div>
          <div className="relative">
            <div className="absolute inset-x-0 bottom-0 h-1/2 " />
            <div className="mx-auto mt-3 max-w-7xl xl:px-8">
              <div className="relative sm:overflow-hidden">
                <div className="absolute inset-0">
                  <img className="object-bottom" src={BgDonate} />
                </div>
                <div className="relative px-4 py-2 sm:px-6 sm:py-4 lg:py-6 lg:px-6">
                  <div className="max-w-sm mx-auto mt-3 sm:max-w-none sm:flex sm:justify-center">
                    <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 lg:max-w-4xl">
                      <div className="text-left">
                        <div className="text-3xl">Our Mission</div>
                        <div className="text-lg">To continue the legacy of the beloved late Fahim Saleh by promoting technology, creativity and entrepreneurship as a means for people to overcome hardship and gain self reliance</div>
                      </div>
                      <DonationWidget />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center my-24 space-y-4">
              <div className="text-2xl font-bold">On Empowerment</div>
              <div className="max-w-xl p-2">Education is not a tool for development - individual, community and the nation. It is the foundation for our future. It is empowerment to make choices and emboldens the youth to chase their dreams <br />-Nita Ambani</div>
          </div>
        </div>

  );
};
export default Donate;
