import SuccessImg from "../Assets/success.png";

const Success = (props) => {
  return (
    <div>
      <div className="flex flex-col items-center my-24">
          <div className="font-bold text-4xl">Thank you for your donation!</div>
          <div className=" mt-20 max-w-6xl">
            <img src={SuccessImg} />
          </div>
      </div>
    </div>
  );
};
export default Success;
