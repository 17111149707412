import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MainVideoThumbnail from "../Assets/main-video-thumbnail.png";
import CelebrationOfLifeThumbnail from "../Assets/celebration-of-life-thumbnail.png";
import CelebrationOfLifeLagosThumbnail from "../Assets/celebration-of-life-lagos.png";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Tributes = (
  <div className="max-w-4xl">
    <div className="mb-12 sm:mb-0">
      <video controls poster={MainVideoThumbnail}>
        <source width="940" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/Main+Video%2C+In+Memoriam.m4v" />
      </video>
      <div className="mt-5 font-bold">In Memoriam</div>
    </div>
    <div className="flex flex-col space-y-12 sm:space-y-0 sm:space-x-10 sm:flex-row sm:mt-14">
      <div>
        <video controls poster={CelebrationOfLifeThumbnail}>
          <source width="460" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/Celebration+Of+Life%2C+Poughkeepsie+New+York%2C+August+2020.m4v" />
        </video>
        <div className="mt-5 text-sm font-bold">Celebration of Life, Poughkeepsie NY, August 2020</div>

      </div>
      <div>
        <video controls  poster={CelebrationOfLifeLagosThumbnail}>
          <source width="460" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/In+Memoriam%2C+Lagos+Nigeria%2C+July+2020.m4v" />
        </video>
        <div className="mt-5 text-sm font-bold">Our Fearless Leader, Lagos Nigeria, July 2020</div>
      </div>
    </div>
    <div className="flex justify-center space-x-10 mt-14">
      <div>
        <video controls>
          <source width="460" src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/Fahim+Turns+30.m4v" />
        </video>
        <div className="mt-5 font-bold">Fahim Turns 30</div>
      </div>
    </div>
  </div>
);

const Memories = (props) => {
  let query = useQuery();
  if (!query.get("tab")) query.set("tab", "tributes"); // Default beingWithFahim

  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="max-w-xl pt-20 pb-14">
          <div className="text-xs font-bold uppercase text-lightgreen">Memories</div>
          <span className="text-3xl font-bold ">Memories of our lives, of our works and our deeds will continue in others<br />-Rosa Parks</span>
        </div>
        <div className="flex justify-center w-full pb-5 space-x-3 border-b lg:space-x-6">
          {/* <Link
            to="/memories?tab=beingWithFahim"
            className={`px-6 py-2 text-sm font-bold rounded-md ${query.get("tab") === "beingWithFahim" ? `border-2 border-lightgreen` : `bg-gray-100 text-gray-400`}`}
          >
            Being with Fahim
          </Link>
          <Link
            to="/memories?tab=textsWithFahim"
            className={`px-6 py-2 text-sm font-bold rounded-md ${query.get("tab") === "textsWithFahim" ? `border-2 border-lightgreen` : `bg-gray-100 text-gray-400`}`}
          >
            Texts with Fahim
          </Link> */}
          <Link
            to="/memories?tab=tributes"
            className={`px-6 py-2 text-sm font-bold rounded-md ${query.get("tab") === "tributes" ? `border-2 border-lightgreen` : `bg-gray-100 text-gray-400`}`}
          >
            Tributes
          </Link>
        </div>
        <div className="my-14">
          {Tributes}
        </div>
      </div>
    </div>
  );
};
export default Memories;
