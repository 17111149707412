import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import LogoFull from "./Assets/logo-full.png";
import { Link } from "react-router-dom";

const navigation = [
  { name: "His Legacy", href: "/legacy" },
  { name: "Our Work", href: "/work" },
  { name: "Memories", href: "/memories" },
  { name: "Contact", href: "/contact" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Navigation = (props) => {
  return (
    <div className="bg-white">
      <header>
        <Popover className="relative bg-white">
          {({ open }) => (
            <>
              <div className="flex items-center justify-between px-4 py-6 mx-auto max-w-7xl sm:px-6 md:justify-start md:space-x-10 lg:px-8">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                  <Link to="/">
                    <span className="sr-only">Fahim The Dream Foundation</span>
                    <img className="w-auto h-8 sm:h-10" src={LogoFull} alt="" />
                  </Link>
                </div>
                <div className="flex items-center justify-end space-x-10">
                  <div className="-my-2 -mr-2 md:hidden">
                    <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lightgreen">
                      <span className="sr-only">Open menu</span>
                      <MenuIcon className="w-6 h-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                  <Popover.Group as="nav" className="hidden space-x-10 md:flex">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="text-base font-medium text-gray-800 hover:text-black"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </Popover.Group>
                  <div className="items-center hidden md:flex md:flex-1">
                    <Link
                      to="/donate"
                      className="px-4 py-2 text-base font-medium text-gray-800 border-2 rounded-md border-lightgreen whitespace-nowrap hover:text-black"
                    >
                      Donate
                    </Link>
                  </div>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="absolute inset-x-0 top-0 z-30 p-2 transition origin-top-right transform md:hidden"
                >
                  <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
                    <div className="px-5 pt-5 pb-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <img
                            className="w-auto h-8 sm:h-10"
                            src={LogoFull}
                            alt=""
                          />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lightgreen">
                            <span className="sr-only">Close menu</span>
                            <XIcon className="w-6 h-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                    </div>
                    <div className="px-5 py-6">
                      <div className="grid grid-cols-2 gap-4">
                        {navigation.map((item) => (
                          <Popover.Button
                            as={Link}
                            key={item.name}
                            to={item.href}
                            className="text-base font-medium text-gray-900 hover:text-gray-700"
                          >
                            {item.name}
                          </Popover.Button>
                        ))}
                      </div>
                      <div className="mt-6">
                        <Popover.Button
                          as={Link}
                          to="/donate"
                          className="px-4 py-2 text-base font-medium text-gray-800 border-2 rounded-md border-lightgreen whitespace-nowrap hover:text-black"
                        >
                          Donate
                        </Popover.Button>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </header>

      <main>{props.children}</main>
    </div>
  );
};
export default Navigation;
