import React, { useState } from "react";

const tabs = [
  { name: "Give Once", href: "#", type: "once" },
  { name: "Yearly", href: "#", type: "yearly" },
  { name: "Monthly", href: "#", type: "monthly" },
];

const products = {
  monthly: [
    { amount: 10, productId: "price_1K5xdyCF2iIQzN9gTssnF21X" },
    { amount: 25, productId: "price_1K5xdyCF2iIQzN9gnZCHanzB" },
    { amount: 50, productId: "price_1K5xdyCF2iIQzN9gs5RJOW3k" },
    { amount: 100, productId: "price_1K5xdyCF2iIQzN9gI12PiH2g" },
    { amount: 250, productId: "price_1K5xdyCF2iIQzN9g4m4mtHwY" },
    { amount: 500, productId: "price_1K5xdyCF2iIQzN9gAjUhIMz1" },
    { amount: 1000, productId: "price_1K5xdyCF2iIQzN9ghrUR0o3C" },
    { amount: 1500, productId: "price_1K5xdyCF2iIQzN9gM4OEypd8" },
  ],
  yearly: [
    { amount: 10, productId: "price_1K5xdyCF2iIQzN9g8X5DsziP" },
    { amount: 25, productId: "price_1K5xdxCF2iIQzN9g5Apw4B50" },
    { amount: 50, productId: "price_1K5xdxCF2iIQzN9giYef3HRV" },
    { amount: 100, productId: "price_1K5xdxCF2iIQzN9gR9dxYeUh" },
    { amount: 250, productId: "price_1K5xdxCF2iIQzN9gvdBlnQ8Y" },
    { amount: 500, productId: "price_1K5xdxCF2iIQzN9gFe6liQP1" },
    { amount: 1000, productId: "price_1K5xdxCF2iIQzN9gcR2aSFou" },
    { amount: 1500, productId: "price_1K5xdxCF2iIQzN9gXEUrlKvg" },
  ],
  once: [
    { amount: 10, productId: "price_1K5xdyCF2iIQzN9gT8OU4IiD" },
    { amount: 25, productId: "price_1K5xdyCF2iIQzN9gkaRgUYK4" },
    { amount: 50, productId: "price_1K5xdyCF2iIQzN9gKAhAeca3" },
    { amount: 100, productId: "price_1K5xdyCF2iIQzN9gj0to6VAN" },
    { amount: 250, productId: "price_1K5xdyCF2iIQzN9gEGiS6E16" },
    { amount: 500, productId: "price_1K5xdyCF2iIQzN9gBFQiPFtA" },
    { amount: 1000, productId: "price_1K5xdyCF2iIQzN9g2GyvSBXH" },
    { amount: 1500, productId: "price_1K5xdyCF2iIQzN9gttslbt0S" },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DonationWidget() {
  const [donationType, setDonationType] = useState("monthly");
  const [selectedAmount, setSelectedAmount] = useState(10);

  const getProductId = () => {
    return products[donationType].find((prod) => prod.amount === selectedAmount)
      .productId;
  };

  return (
    <div className="rounded-lg shadow-lg">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full border-gray-300 focus:ring-lightgreen focus:border-lightgreen"
          value={donationType}
          onChange={(e) => {console.log("Change type: ", e); setDonationType(e.target.value)}}
        >
          {tabs.map((tab) => (
            <option value={tab.type} selected={donationType === tab.type}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="">
        <nav
          className="relative z-0 hidden divide-x divide-gray-200 shadow sm:flex"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <div
              key={tabIdx}
              onClick={() => setDonationType(tab.type)}
              className={classNames(
                tab.type === donationType
                  ? "text-gray-900 bg-white"
                  : "text-gray-400 hover:text-gray-700 bg-neutral hover:bg-gray-100",
                "group cursor-pointer relative min-w-0 flex-1 overflow-hidden py-3 px-3 text-sm font-medium text-center  focus:z-10"
              )}
              aria-current={tab.type === donationType ? "page" : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? "bg-lightgreen" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </div>
          ))}
        </nav>
        <div className="p-4 bg-white">
          <div className="pb-4 font-semibold">Choose an amount to donate</div>
          <div className="grid grid-flow-row grid-cols-4 gap-4 mb-8">
            {products[donationType].map((prod, prodIdx) => (
              <div
                key={prodIdx}
                onClick={() => setSelectedAmount(prod.amount)}
                className={classNames(
                  prod.amount === selectedAmount
                    ? "bg-lightgreen"
                    : "bg-gray-100 border-gray-200 border-2 text-gray-400",
                  "flex w-16 sm:w-24 h-12 justify-center items-center font-bold text-xl rounded-lg p-2 cursor-pointer"
                )}
              >
                ${prod.amount}
              </div>
            ))}
          </div>
          <button
            onClick={() => {
              window.stripe.redirectToCheckout({
                lineItems: [{ price: getProductId(), quantity: 1 }],
                mode: donationType === "once" ? "payment" : "subscription",
                successUrl: "https://www.fahimthedream.org/success",
                cancelUrl: "https://www.fahimthedream.org",
              });
            }}
            className="w-full py-4 text-sm font-bold uppercase rounded-md bg-lightgreen"
          >
            Donate {donationType === "once" ? "" : donationType}
          </button>
        </div>
      </div>
    </div>
  );
}
