import TheDream from "../Assets/img-the-dream.jpg";
import HomeVideo from "../Assets/home.mp4";
import DonationWidget from "../DonationWidget";
import { Link } from "react-router-dom";

const Home = (props) => {

  return (
    <div>
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 " />
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="relative shadow-xl sm:rounded-md sm:overflow-hidden">
            <div className="absolute inset-0">
              <div className="absolute inset-0 bg-gray-800 opacity-20 mix-blend-multiply" />
              <video
                autoPlay
                loop
                muted
                className="absolute object-cover w-auto min-w-full min-h-full"
              >
                <source
                  src={HomeVideo}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="relative px-4 py-16 bg-black sm:px-6 sm:py-24 lg:py-32 lg:px-8 bg-opacity-20">
              <div className="max-w-sm mx-auto mt-10 sm:max-w-none sm:flex sm:justify-center">
                <div className="items-center space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 lg:max-w-4xl">
                  <div className="p-2 text-center ">
                    <div className="p-2 space-y-3 text-white rounded-md bg-black/50">
                      <div className="text-xs font-bold uppercase text-lightgreen">Our Mission</div>
                      <div className="text-xl font-bold ">To continue the legacy of the beloved late Fahim Saleh by promoting technology, creativity and entrepreneurship as a means for people to overcome hardship and gain self reliance</div>
                    </div>
                  </div>
                  <DonationWidget />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center my-24 space-y-4">
        <div className="text-sm font-bold uppercase text-lightgreen">Our Work</div>
        <div className="max-w-2xl text-2xl font-bold">Project First Ladies of Lagos</div>
        <div className="max-w-2xl px-6">Fahim believed in giving first to those he loved. He loved Nigeria and its people. He loved Gokada and its pilots. Therefore, the first group of people the foundation has chosen to empower is the wives of the beloved G-Pilots of <a href="https://www.gokada.ng/" target="_blank">Gokada</a>, based in Lagos, Nigeria. The foundation will partner with select organizations in Lagos with shared values to provide women with skills training to pursue entrepreneurship and job opportunities.</div>
        <div className="mt-6 "><Link to="/work" className="p-3 px-6 text-xs font-bold uppercase border-2 rounded-md border-lightgreen">Learn More</Link></div>

      </div>
      <div className="flex flex-col items-center my-24 py-24 space-y-4 bg-[#f7faf9]">
        <div className="text-sm font-bold uppercase text-lightgreen">Memories</div>
        <div className="max-w-2xl text-2xl font-bold">Being with Fahim</div>
        <div className="flex flex-col mt-10 space-x-0 space-y-4 sm:space-x-10 sm:space-y-0 sm:flex-row">
          <div>
            <video width="220" controls><source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/Birthday+Gifts-1.m4v" /></video>
            <div className="mt-5 text-xl font-bold">Birthday Swag</div>
          </div>
          <div>
            <video width="220" controls><source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/Dad+Is+Anxious-2.m4v" /></video>
            <div className="mt-5 text-xl font-bold">Dad Is Anxious</div>
          </div>
          <div className="mt-24">
            <video width="220" height="390" controls><source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/G-Pilot+Love-3.m4v" /></video>
            <div className="mt-5 text-xl font-bold">G-Pilot Love</div>
          </div>
          <div>
            <video width="220" controls><source src="https://fahimthedream-static.s3.us-west-2.amazonaws.com/Uncle+Fahim-4.m4v" /></video>
            <div className="mt-5 text-xl font-bold">Uncle Fahim</div>
          </div>
        </div>

        <Link to="/memories" className="p-3 px-6 mt-16 text-xs font-bold uppercase border-2 rounded-md border-lightgreen">Continue to all memories</Link>
      </div>
      <div className="flex flex-col items-center my-24 space-y-4">
        <div className="text-sm font-bold uppercase text-lightgreen">His Legacy</div>

        <div className="max-w-2xl text-2xl font-bold">Fahim’s Legacy in One Word</div>
        <div className="max-w-3xl px-6">The greatest legacy one can pass on is not money or other material things accumulated in one’s life, but rather a legacy of character and faith. If you had to describe Fahim’s legacy in one word, what would it be? </div>

        <Link to="/legacy" className="p-3 px-6 text-xs font-bold uppercase border-2 rounded-md border-lightgreen">Continue to legacy</Link>
      </div>
      <div className="flex flex-col items-center mt-24 py-24 space-y-4 bg-[#f7faf9]">
        <div className="max-w-2xl text-2xl font-bold">The Dream Lives On!</div>
        <Link to="/donate" className="p-3 px-6 mt-10 text-xs font-bold uppercase rounded-md bg-lightgreen">Donate</Link>
      </div>
      <div className="flex flex-col items-center bg-[#f7faf9]">
        <img src={TheDream} />
      </div>
    </div>

  );
};
export default Home;


