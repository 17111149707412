import "./App.css";
import Navigation from "./Navigation";
import Home from "./Screens/Home";
import Donate from "./Screens/Donate";
import Legacy from "./Screens/Legacy";
import Memories from "./Screens/Memories";
import Work from "./Screens/Work";
import Success from "./Screens/Success";
import Privacy from "./Screens/Privacy";
import Contact from "./Screens/Contact";
import Footer from "./Footer";
import ScrollToTop from "./scrollToTop";

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navigation>
          <Switch>
            <Route path="/donate">
              <Donate />
            </Route>
            <Route path="/legacy">
              <Legacy />
            </Route>
            <Route path="/memories">
              <Memories />
            </Route>
            <Route path="/work">
              <Work />
            </Route>
            <Route path="/success">
              <Success />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Navigation>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
