import React, { useState } from "react";
import LogoFull from "./Assets/logo-full.png";
import { Link } from "react-router-dom";
import axios from "axios";

const Footer = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailAdded, setEmailAdded] = useState(false);

  const addToNewsletter = async (e) => {
    e.preventDefault();

    setEmailAdded(true);
    setFirstName("");
    setLastName("");
    setEmail("");

    try {
      const response = await axios.post('https://api.fahimthedream.org/newsletter', {
        firstName,
        lastName,
        email
      });
  
      console.log("Repsonse: ", response.data);
    } catch (error) {
      console.log(JSON.stringify(error));
    };
  }

  return (
    <footer className="bg-[#f7faf9] divide-y divider-gray-400 mx-auto">
      <div className="mx-auto max-w-7xl">
        <div className="px-4 py-12 border-b sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex flex-col items-start justify-start md:order-1">
            <div className="text-xl font-bold">We Have Big Plans</div>
            <div>Get our emails to stay in the know</div>
          </div>
          {emailAdded ? (
            <div className="space-x-8 font-bold md:order-2 from-thegreen">
              Great! We'll keep you updated.
            </div>
          ) : (
            <div className="space-x-8 md:order-2">
              <form onSubmit={addToNewsletter}>
                <div className="flex flex-col mt-1 space-y-4 rounded-md shadow-sm sm:space-y-0 sm:flex-row">
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <input
                      required
                      type="text"
                      name="firstName"
                      id="firstName"
                      onChange={e => {
                        setFirstName(e.target.value);
                      }}
                      className="block w-full pl-2 border-gray-400 rounded-none focus:border-gray-500 rounded-l-md sm:text-sm"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <input
                      required
                      type="text"
                      name="lastName"
                      id="lastName"
                      onChange={e => {
                        setLastName(e.target.value);
                      }}
                      className="block w-full pl-2 border-gray-400 rounded-none focus:border-gray-500 sm:text-sm"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <input
                      required
                      type="email"
                      name="email"
                      id="email"
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                      className="block w-full pl-2 border-gray-400 rounded-none focus:border-gray-500 sm:text-sm"
                      placeholder="Email"
                    />
                  </div>
                  <button
                    type="submit"
                    className="relative inline-flex items-center px-10 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-lightgreen hover:bg-gray-100 focus:border-gray-500"
                  >
                    <span>JOIN</span>
                  </button>

                </div>
              </form>
            </div>)}

        </div>
        <div className="px-4 py-12 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-1">
            <img className="w-auto h-8 sm:h-10" src={LogoFull} alt="" />
          </div>
          <div className="mt-10 space-x-8 sm:mt-0 md:order-2">
            <Link
              to="/contact"
              className="mt-8 text-base font-bold text-gray-500 md:mt-0 hover:text-gray-900"
            >
              Contact
            </Link>
            <Link
              to="/donate"
              className="px-4 py-2 text-base font-bold text-gray-500 border-2 rounded-md border-lightgreen whitespace-nowrap hover:text-gray-900"
            >
              Donate
            </Link>
          </div>
        </div>
        <div className="px-4 py-6 mx-auto border-t max-w-7xl sm:px-4 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-1"><Link to="/privacy" className="text-xs text-gray-400 cursor-pointer">Privacy Policy</Link></div>
          <div className="mt-8 md:mt-0 md:order-2">
            <p className="text-xs text-center text-gray-400">
              © {new Date().getFullYear()} | 7 Baird Ct. Syosset, NY 11791 |  Fahim the Dream Foundation,  a US 501 (c)(3) public charity, EIN 87-1579254
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
